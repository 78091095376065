<template>
    <div class="m-foldblock">
        <el-divider content-position="left">
            <div class="m-foldblock-title">
                <slot name="icon"></slot>
                <slot name="title">
                    {{ title }}
                </slot>
            </div>
            <span class="u-collapse" v-if="!fixed" @click="collapse" :class="{ on: fold }">
                <el-icon size="14"><DCaret /></el-icon>
                <span class="u-collapse-text">{{ fold ? "展开" : "折叠" }}</span>
            </span>
            <!-- <div v-if="showToolbarOnCollapse || !fold" class="m-foldblock-toolbar">
                <slot name="toolbar"></slot>
            </div> -->
        </el-divider>
        <div class="m-foldblock-content" :class="{ on: fold }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "FoldBlock",
    props: {
        title: {
            type: String,
            default: "条件筛选",
        },
        fold: {
            type: Boolean,
            default: false,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
        showToolbarOnCollapse: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:fold"],
    computed: {},
    methods: {
        collapse: function () {
            this.$emit("update:fold", !this.fold);
        },
    },
};
</script>

<style lang="less">
@import "~@/assets/css/common/fold_block.less";
</style>
