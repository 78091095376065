module.exports = {
    // document title
    Title: "DeepBerry",

    // API
    API: {
        cms: "https://cms.deepberry.cn",
        titan: "https://api.deepberry.cn",
        next: "https://next.deepberry.cn",
        ws: "https://next.deepberry.cn",
    },

    // Menu
    Menu: [
        {
            title: "综合管理平台",
            key: "fms",
            path: "/fms/index",
            disabled: false,
        },
        // {
        //     title: "运营管理平台",
        //     key: "omp",
        //     path: "/omp/index",
        //     disabled: false,
        // },
        {
            title: "内容管理平台",
            key: "cms",
            path: "/cms/index",
            disabled: false,
        },
        {
            title: "开发管理平台",
            key: "dev",
            path: "/dev/index",
            disabled: false,
        },
    ],

    // Panel
    Panel: [{ title: "Test", path: "/test", disabled: true }],

    // Token
    tokenKey: "DEEPBERRY_CMS_TOKEN",
    tokenExpires: 2592000000, // 30 days
};
