/**
 * 统计各个模块的待处理事项
 */
import { getWwwCount, getBillCount } from "@/service/count.js";
import { getOrderList } from "@/service/mall_handmade_order";
import { getPendingAfterSalesBucketCount } from "@/service/after-sales";
import { getMyIntentionCount } from "@/service/intention";
import store from "@/store";

export const useCount = () => {
    const countWww = async () => {
        const res = await getWwwCount();
        const data = res.data.data;

        store.commit("app/SET_COUNTS", {
            key: "cms-service",
            value: Object.values(data).reduce((a, b) => a + b, 0),
        });

        Object.keys(data).forEach((key) => {
            store.commit("app/SET_COUNTS", { key, value: data[key] });
        });
    };

    const countBill = async () => {
        const res = await getBillCount();
        const data = res.data.data;

        store.commit("app/SET_COUNTS", {
            key: "fms-bill",
            value: data,
        });

        store.commit("app/SET_COUNTS", {
            key: "fms-bill-statement",
            value: data,
        });
    };

    const countOrderConfirm = async () => {
        // 支付成功
        const res = await getOrderList({
            pay_status: 2,
        });
        const data = res.data.data?.total || 0;

        store.commit("app/SET_COUNTS", {
            key: "fms-order",
            value: data,
        });

        store.commit("app/SET_COUNTS", {
            key: "fms-order-business",
            value: data,
        });
    };

    const countPendingWorkflow = async () => {
        const res = await getPendingAfterSalesBucketCount();

        const statusMap = res.data?.data;
        store.commit("app/SET_COUNTS", {
            key: "workflowStatusMap",
            value: statusMap,
        });

        const data = statusMap?.[0] || 0;

        store.commit("app/SET_COUNTS", {
            key: "fms-after-sales",
            value: data,
        });

        store.commit("app/SET_COUNTS", {
            key: "fms-workflows-mine",
            value: data,
        });
    };

    const countMyIntentions = async () => {
        const res = await getMyIntentionCount({ status: "0,10,20" });

        const data = res.data.data || 0;
        store.commit("app/SET_COUNTS", {
            key: "fms-order",
            value: data,
        });
        store.commit("app/SET_COUNTS", {
            key: "fms-order-intention",
            value: data,
        });
    };

    return {
        countWww,
        countBill,
        countOrderConfirm,
        countPendingWorkflow,
        countMyIntentions,
    };
};
