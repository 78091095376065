export default [
    {
        path: "/common",
        name: "common__root__",
        redirect: {
            name: "common-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "common",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "common-index",
                component: () => import("@/views/common/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/common/account",
        name: "common_account",
        meta: {
            title: "个人中心",
            icon: "user",
            parent: "common",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/common/account/setting",
        children: [
            {
                path: "setting",
                name: "common-account-setting",
                component: () => import("@/views/common/account/setting.vue"),
                meta: {
                    title: "资料设置",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "message",
                name: "common-account-message",
                component: () => import("@/views/common/account/message.vue"),
                meta: {
                    title: "通知消息",
                    icon: "message",
                    affix: false,
                },
            },
            {
                path: "wechat-bind",
                name: "common-account-wechat-bind",
                component: () => import("@/views/common/account/wechat-bind.vue"),
                hidden: true,
                meta: {
                    title: "微信绑定",
                    icon: "message",
                    affix: false,
                    activeMenu: "/common/account/setting",
                },
            },
        ],
    },
];
