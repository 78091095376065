/*=========================================================*/
// route.hidden 如果设置为true，则不会显示在tabs菜单中，侧边栏也不会展示
// route.meta.affix 如果设置为true，则标签不可关闭
/*=========================================================*/

// 1.Dependency
import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { filter, flatten } from "lodash";
import User from "@/utils/user.js";
import { getPageTitle } from "@/utils/common";
import store from "../store";
const DEV = process.env.NODE_ENV === "development";

// 2.Routes
const files = require.context("./", true, /\.js$/);
const routesModules = files.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = files(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});

export const constantRoutes = [
    ...flatten(
        filter(routesModules, (module) => {
            return module;
        })
    ),
];

// 4.Build An Instance
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history:createWebHashHistory()
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
});

// 路由守卫
router.beforeEach(async (to, from, next) => {
    document.title = getPageTitle(to.meta.title);

    const isLogin = User.isLogin();

    if (to.path === "/login") {
        next();
    } else if (!isLogin && !to.meta.isPublic) {
        next({ name: "login", query: { redirect: to.fullPath } }); // 未登录且非公开页面，跳转到登录
    } else if (hasPermission(to)) {
        store.commit("permission/CHANGE_HAS_PERMISSION", true);

        store.commit("permission/SET_ACTIVE_MENU", to?.meta?.parent);

        store.dispatch("app/loadMyPermission").then(() => {
            store.dispatch("permission/generateRoutes", User.getGroup());
        });

        next(); // 有权限，继续导航
    } else {
        store.commit("permission/CHANGE_HAS_PERMISSION", false);
        next(false); // 无权限，提示无权限
    }
});

// 检查权限
function hasPermission(to) {
    const isSuperAdmin = User.isSuperAdmin();
    const myPermission = User.getPermission() || [];
    const metaGroup = to.meta.group;
    const metaPermission = to.meta.permission || "";
    const myGroup = User.getGroup();

    // 如果是公开页面，可访问
    if (to.meta.isPublic) return true;
    // 如果meta没有设置role 和 permission 正常显示
    if (!metaGroup && !metaPermission) return true;
    // 如果是超级管理员，可访问
    if (isSuperAdmin) return true;
    // 如果有设置页面权限且我的权限组大于等于页面权限组，可访问
    if (metaGroup && myGroup >= metaGroup) return true;
    // 如果设置权限且我的权限列表中有该权限，可访问
    if (metaPermission && myPermission.some((item) => item.action === metaPermission)) return true;

    return false;
}

export default router;
